
/*reset*/
html,
body,
header,
div,
h1,
h2,
h3,
h4,
h5,
h6,
button,
p,
ul,
select,
option {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  font-size: 16px;
  font-family: NotoSansCJKkr, sans-serif;
  letter-spacing: -0.4px;
}
html,body{
  height: calc(100% - 26px) !important;
  background-color: #e9edef;
}
select{
  outline: none;
}
button{
  cursor: pointer;
  outline: none;
}
input{
  outline: none;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
  display: block;
  height: 0px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #c5cbd4;
  border-radius: 8px;
}
.kpi-wrap{

  input[type=range]{
    &.input{
      -moz-appearance: auto !important;
      -webkit-appearance: auto !important;
      appearance: auto !important;
    }
  }
}
.datepicker-wrap{
  .dp-picker-input{
    width: 132px !important;
    height: 32px;
    padding: 8px;
    border-radius: 4px;
  }
}
.theme-dark{

  .datepicker-wrap{
    .dp-picker-input{
      background-color: #2a2c2f;
      color: #fff;
      border: 1px solid #909fb7;
    }
  }
}