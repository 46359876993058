/* jsTree default theme */
.jstree-node,
.jstree-children,
.jstree-container-ul {
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: none;
  list-style-image: none;
}

.jstree-node {
  white-space: nowrap;
}

.jstree-anchor {
  display: inline-block;
  color: #2a2c2f;
  white-space: nowrap;
  padding: 0 4px 0 1px;
  font-size: 14px;
  font-weight: 400;
}

.jstree-anchor:focus {
  outline: 0;
}

.jstree-anchor,
.jstree-anchor:link,
.jstree-anchor:visited,
.jstree-anchor:hover,
.jstree-anchor:active {
  text-decoration: none;
  /* color: inherit; */
}

.jstree-icon {
  display: inline-block;
  text-decoration: none;
  margin: 0;
  padding: 0;
  vertical-align: top;
  text-align: center;
}

.jstree-icon:empty {
  display: inline-block;
  text-decoration: none;
  margin: 0;
  padding: 0;
  vertical-align: top;
  text-align: center;
}

.jstree-ocl {
  cursor: pointer;
}

.jstree-leaf > .jstree-ocl {
  cursor: default;
}

.jstree .jstree-open > .jstree-children {
  display: block;
  /* background:url("./ic-tree.png") no-repeat -303px -96px; */
}

.jstree .jstree-closed > .jstree-children,
.jstree .jstree-leaf > .jstree-children {
  display: none;
}

.jstree-anchor > .jstree-themeicon {
  margin-right: 6px;
}

.jstree-no-icons .jstree-themeicon,
.jstree-anchor > .jstree-themeicon-hidden {
  display: none;
}

.jstree-hidden,
.jstree-node.jstree-hidden {
  display: none;
}

.jstree-rtl .jstree-anchor {
  padding: 0 1px 0 4px;
}

.jstree-rtl .jstree-anchor > .jstree-themeicon {
  margin-left: 2px;
  margin-right: 0;
}

.jstree-rtl .jstree-node {
  margin-left: 0;
}

.jstree-rtl .jstree-container-ul > .jstree-node {
  margin-right: 0;
}

.jstree-wholerow-ul {
  position: relative;
  display: inline-block;
  min-width: 100%;
}

.jstree-wholerow-ul .jstree-leaf > .jstree-ocl {
  cursor: pointer;
}

.jstree-wholerow-ul .jstree-anchor,
.jstree-wholerow-ul .jstree-icon {
  position: relative;
}

.jstree-wholerow-ul .jstree-wholerow {
  width: 100%;
  cursor: pointer;
  position: absolute;
  left: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.jstree-contextmenu .jstree-anchor {
  -webkit-user-select: none;
  /* disable selection/Copy of UIWebView */
  -webkit-touch-callout: none;
  /* disable the IOS popup when long-press on a link */
}

.vakata-context {
  display: none;
  z-index: 100;
}

.vakata-context,
.vakata-context ul {
  margin: 0;
  padding: 2px;
  position: absolute;
  background: #f5f5f5;
  border: 1px solid #979797;
  box-shadow: 2px 2px 2px #999999;
}

.vakata-context ul {
  list-style: none;
  left: 100%;
  margin-top: -2.7em;
  margin-left: -4px;
}

.vakata-context .vakata-context-right ul {
  left: auto;
  right: 100%;
  margin-left: auto;
  margin-right: -4px;
}

.vakata-context li {
  list-style: none;
}

.vakata-context li > a {
  display: block;
  padding: 0 6px 0 6px;
  text-decoration: none;
  width: auto;
  color: black;
  white-space: nowrap;
  line-height: 2.4em;
  text-shadow: 1px 1px 0 white;
  border-radius: 1px;
  font-size: 13px;
}

.vakata-context li > a:hover {
  position: relative;
  background-color: #e8eff7;
  box-shadow: 0 0 2px #0a6aa1;
}

.vakata-context li > a.vakata-context-parent {
  background-image: url('data:image/gif;base64,R0lGODlhCwAHAIAAACgoKP///yH5BAEAAAEALAAAAAALAAcAAAIORI4JlrqN1oMSnmmZDQUAOw==');
  background-position: right center;
  background-repeat: no-repeat;
}

.vakata-context li > a:focus {
  outline: 0;
}

.vakata-context .vakata-context-hover > a {
  position: relative;
  background-color: #e8eff7;
  box-shadow: 0 0 2px #0a6aa1;
}

.vakata-context .vakata-context-separator > a,
.vakata-context .vakata-context-separator > a:hover {
  background: white;
  border: 0;
  border-top: 1px solid #e2e3e3;
  height: 1px;
  min-height: 1px;
  max-height: 1px;
  padding: 0;
  margin: 0 0 0 2.4em;
  border-left: 1px solid #e0e0e0;
  text-shadow: 0 0 0 transparent;
  box-shadow: 0 0 0 transparent;
  border-radius: 0;
}

.vakata-context .vakata-contextmenu-disabled a,
.vakata-context .vakata-contextmenu-disabled a:hover {
  color: silver;
  background-color: transparent;
  border: 0;
  box-shadow: 0 0 0;
}

.vakata-context li > a > i {
  text-decoration: none;
  display: inline-block;
  width: 2.4em;
  height: 2.4em;
  background: transparent;
  margin: 0 0 0 -2em;
  vertical-align: top;
  text-align: center;
  line-height: 2.4em;
}

.vakata-context li > a > i:empty {
  width: 2.4em;
  line-height: 2.4em;
}

.vakata-context li > a .vakata-contextmenu-sep {
  display: inline-block;
  width: 1px;
  height: 2.4em;
  background: white;
  margin: 0 0.5em 0 0;
  border-left: 1px solid #e2e3e3;
}

.vakata-context .vakata-contextmenu-shortcut {
  font-size: 0.8em;
  color: silver;
  opacity: 0.5;
  display: none;
}

.vakata-context-rtl ul {
  left: auto;
  right: 100%;
  margin-left: auto;
  margin-right: -4px;
}

.vakata-context-rtl li > a.vakata-context-parent {
  background-image: url('data:image/gif;base64,R0lGODlhCwAHAIAAACgoKP///yH5BAEAAAEALAAAAAALAAcAAAINjI+AC7rWHIsPtmoxLAA7');
  background-position: left center;
  background-repeat: no-repeat;
}

.vakata-context-rtl .vakata-context-separator > a {
  margin: 0 2.4em 0 0;
  border-left: 0;
  border-right: 1px solid #e2e3e3;
}

.vakata-context-rtl .vakata-context-left ul {
  right: auto;
  left: 100%;
  margin-left: -4px;
  margin-right: auto;
}

.vakata-context-rtl li > a > i {
  margin: 0 -2em 0 0;
}

.vakata-context-rtl li > a .vakata-contextmenu-sep {
  margin: 0 0 0 0.5em;
  border-left-color: white;
  background: #e2e3e3;
}

#jstree-marker {
  position: absolute;
  top: 0;
  left: 0;
  margin: -5px 0 0 0;
  padding: 0;
  border-right: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
}

#jstree-dnd {
  line-height: 16px;
  margin: 0;
  padding: 4px;
}

#jstree-dnd .jstree-icon,
#jstree-dnd .jstree-copy {
  display: inline-block;
  text-decoration: none;
  margin: 0 2px 0 0;
  padding: 0;
  width: 16px;
  height: 16px;
}

#jstree-dnd .jstree-ok {
  background: green;
}

#jstree-dnd .jstree-er {
  background: red;
}

#jstree-dnd .jstree-copy {
  margin: 0 2px 0 2px;
}

.jstree-default .jstree-node,
.jstree-default .jstree-icon {
  background-repeat: no-repeat;
  background-color: transparent;
}

.jstree-default .jstree-anchor,
.jstree-default .jstree-animated,
.jstree-default .jstree-wholerow {
  transition: background-color 0.15s, box-shadow 0.15s;
}

.jstree-default .jstree-hovered {
  color: #0a78ff;
  font-weight: 400;
}

.jstree-default .jstree-context {
  /* background: #e7f4f9; */
  border-radius: 2px;
  /* box-shadow: inset 0 0 1px #cccccc; */
}

.jstree-default .jstree-clicked {
  color: #0a78ff;
  font-weight: 400;
}

.jstree-default .jstree-no-icons .jstree-anchor > .jstree-themeicon {
  display: none;
}

.jstree-default .jstree-disabled {
  background: transparent;
  color: #666666;
}

.jstree-default .jstree-disabled.jstree-hovered {
  background: transparent;
  box-shadow: none;
}

.jstree-default .jstree-disabled.jstree-clicked {
  background: red;
}

.jstree-default .jstree-disabled > .jstree-icon {
  opacity: 0.8;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='jstree-grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#jstree-grayscale");
  /* Firefox 10+ */
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+ & Safari 6+ */
}

.jstree-search {
  font-style: italic;
  color: #0a78ff !important;
  font-weight: bold;
}

.jstree-default .jstree-no-checkboxes .jstree-checkbox {
  display: none !important;
}

.jstree-default.jstree-checkbox-no-clicked .jstree-clicked {
  background: transparent;
  box-shadow: none;
}

.jstree-default.jstree-checkbox-no-clicked .jstree-clicked.jstree-hovered {
  background-color: #e7f4f9;
}
.theme-dark
  .jstree-default.jstree-checkbox-no-clicked
  .jstree-clicked.jstree-hovered {
  background-color: #003876;
}

.jstree-default.jstree-checkbox-no-clicked
  > .jstree-wholerow-ul
  .jstree-wholerow-clicked {
  background: transparent;
}

.jstree-default.jstree-checkbox-no-clicked
  > .jstree-wholerow-ul
  .jstree-wholerow-clicked.jstree-wholerow-hovered {
  background: #e7f4f9;
}

.jstree-default > .jstree-striped {
  min-width: 100%;
  display: inline-block;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAkCAMAAAB/qqA+AAAABlBMVEUAAAAAAAClZ7nPAAAAAnRSTlMNAMM9s3UAAAAXSURBVHjajcEBAQAAAIKg/H/aCQZ70AUBjAATb6YPDgAAAABJRU5ErkJggg==')
    left top repeat;
}

.jstree-default > .jstree-wholerow-ul .jstree-hovered,
.jstree-default > .jstree-wholerow-ul .jstree-clicked {
  background: transparent;
  box-shadow: none;
  border-radius: 0;
}

.jstree-default .jstree-wholerow {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.jstree-default .jstree-wholerow-hovered {
  background-color: #e7f4f9;
}
.theme-dark .jstree-default .jstree-wholerow-hovered {
  background-color: #003876;
}

/* .jstree-default .jstree-wholerow-clicked {
  background: #beebff;
  background: -webkit-linear-gradient(top, #beebff 0%, #a8e4ff 100%);
  background: linear-gradient(to bottom, #beebff 0%, #a8e4ff 100%);
} */
.jstree-default .jstree-node {
  min-height: 24px;
  line-height: 24px;
  padding-left: 20px;
  min-width: 24px;
}

.jstree-default .jstree-anchor {
  height: 32px;
  font-size: 14px;
  vertical-align: top;
  line-height: 31px;
  padding-left: 2px;
}

.jstree-default .jstree-icon {
  width: 20px;
  height: 20px;
  line-height: 20px;
}

.jstree-default .jstree-icon:empty {
  width: 26px;
  height: 32px;
  /* line-height: 20px; */
}

.jstree-default.jstree-rtl .jstree-node {
  margin-right: 24px;
}

.jstree-default .jstree-wholerow {
  height: 32px;
}

.jstree-default .jstree-node,
#dynamic-jstree .jstree-icon {
  background-image: url('/assets/css/white/jstree/ic-tree.png');
}

.jstree-default .jstree-node {
  background-position: -307px -4px;
  background-repeat: repeat-y;
  background-image: none;
  position: relative;
}

/* .jstree-default .jstree-last {
  background: transparent;
} */

/* .jstree-default .jstree-node::before,
.jstree-default .jstree-node::after{
  content: '';
  display: inline-block;
  width: 25px;
  height: 30px;
  background-image: url("ic-tree.png");
  background-repeat: repeat;
  position: absolute;
}
.jstree-default .jstree-node::before{
  background-position: -65px -37px;
  left: 28px;
  top: 35px;
}
.jstree-default .jstree-node::after{
  background-position: -65px 2px;
  left: 18px;
  top: 14px;
}
.jstree-default .jstree-leaf::after{
  display: none;
} */

#dynamic-jstree > .jstree-container-ul > .jstree-node > .jstree-ocl {
  background-position: -282px -174px;
}

#dynamic-jstree > .jstree-container-ul > .jstree-open > .jstree-ocl {
  background-position: -319px -150px;
}

#dynamic-jstree > .jstree-container-ul > .jstree-closed > .jstree-ocl {
  background-position: -282px -174px;
}

.jstree-default .jstree-leaf > .jstree-ocl {
  background-position: -73px -14px;
  width: 26px;
  visibility: hidden;
}

.jstree-leaf.jstree-last .jstree-icon.jstree-ocl {
  background-position: -13px -114px;
}

.jstree-default > .jstree-no-dots .jstree-node,
.jstree-default > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  /* background: transparent; */
}

/* .jstree-default>.jstree-no-dots .jstree-open>.jstree-ocl {
  background-position: -36px -4px;
}

.jstree-default>.jstree-no-dots .jstree-closed>.jstree-ocl {
  background-position: -4px 0px;
} */

.jstree-default .jstree-disabled {
  background: transparent;
}

.jstree-default .jstree-disabled.jstree-hovered {
  background: transparent;
}

.jstree-default .jstree-disabled.jstree-clicked {
  background: #efefef;
}

.jstree-default .jstree-checkbox {
  background-position: -164px -1px;
}

/* .jstree-default .jstree-checkbox:hover {
  background-position: -164px -33px;
} */
.jstree-default.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox,
.jstree-default .jstree-checked > .jstree-checkbox {
  background-position: -228px -4px;
}

/* .jstree-default.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox:hover,
.jstree-default .jstree-checked > .jstree-checkbox:hover {
  background-position: -228px -36px;
} */
.jstree-default .jstree-anchor > .jstree-undetermined {
  background-position: -196px -2px;
}

.jstree-default .jstree-anchor > .jstree-undetermined:hover {
  background-position: -196px -36px;
}

.jstree-default .jstree-checkbox-disabled {
  opacity: 0.8;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='jstree-grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#jstree-grayscale");
  /* Firefox 10+ */
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+ & Safari 6+ */
}

.jstree-default > .jstree-striped {
  background-size: auto 48px;
}

.jstree-default.jstree-rtl .jstree-node {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==');
  background-position: 100% 1px;
  background-repeat: repeat-y;
}

.jstree-default.jstree-rtl .jstree-last {
  background: transparent;
}

.jstree-default.jstree-rtl .jstree-open > .jstree-ocl {
  background-position: -132px -36px;
}

.jstree-default.jstree-rtl .jstree-closed > .jstree-ocl {
  background-position: -100px -36px;
}

.jstree-default.jstree-rtl .jstree-leaf > .jstree-ocl {
  background-position: -68px -36px;
}

.jstree-default.jstree-rtl > .jstree-no-dots .jstree-node,
.jstree-default.jstree-rtl > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: transparent;
}

.jstree-default.jstree-rtl > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -36px -36px;
}

.jstree-default.jstree-rtl > .jstree-no-dots .jstree-closed > .jstree-ocl {
  background-position: -4px -36px;
}

/* .jstree-default .jstree-themeicon-custom {
  background-color: transparent;
  background-image: none;
  background-position: 0 0;
} */
.jstree-default > .jstree-container-ul .jstree-loading > .jstree-ocl {
  background: url('throbber.gif') center center no-repeat;
}

.jstree-default .jstree-file {
  background: url('ic-tree.png') -100px -68px no-repeat;
}

.jstree-default .jstree-folder {
  background: url('ic-tree.png') -260px -4px no-repeat;
}

.jstree-default > .jstree-container-ul > .jstree-node {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
}

.jstree-default > .jstree-container-ul > .jstree-node:after {
  content: '';
  display: block;
  position: absolute;
  left: 10px;
  top: 44px;
  width: 24px;
  height: 24px;
  background-image: url('ic-tree.png');
  background-position: -328px 0;
  display: none;
}

.jstree-default > .jstree-container-ul > .jstree-node.jstree-last:after {
  background-image: none;
}

#jstree-dnd.jstree-default {
  line-height: 24px;
  padding: 0 4px;
}

#jstree-dnd.jstree-default .jstree-ok,
#jstree-dnd.jstree-default .jstree-er {
  background-image: url('ic-tree.png');
  background-repeat: no-repeat;
  background-color: transparent;
}

#jstree-dnd.jstree-default i {
  background: transparent;
  width: 24px;
  height: 24px;
  line-height: 24px;
}

#jstree-dnd.jstree-default .jstree-ok {
  background-position: -10px -68px;
}

#jstree-dnd.jstree-default .jstree-er {
  background-position: -43px -68px;
}

.jstree-default .jstree-ellipsis {
  overflow: hidden;
}

.jstree-default .jstree-ellipsis .jstree-anchor {
  width: calc(100% - 29px);
  text-overflow: ellipsis;
  overflow: hidden;
}

.jstree-default .jstree-ellipsis.jstree-no-icons .jstree-anchor {
  width: calc(100% - 5px);
}

.jstree-default.jstree-rtl .jstree-node {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==');
}

.jstree-default.jstree-rtl .jstree-last {
  background: transparent;
}

.jstree-default-small .jstree-node {
  min-height: 18px;
  line-height: 18px;
  margin-left: 18px;
  min-width: 18px;
}

.jstree-default-small .jstree-anchor {
  line-height: 18px;
  height: 18px;
}

.jstree-default-small .jstree-icon {
  width: 18px;
  height: 18px;
  line-height: 18px;
}

.jstree-default-small .jstree-icon:empty {
  width: 18px;
  height: 18px;
  line-height: 18px;
}

.jstree-default-small.jstree-rtl .jstree-node {
  margin-right: 18px;
}

.jstree-default-small .jstree-wholerow {
  height: 18px;
}

.jstree-default-small .jstree-node,
.jstree-default-small .jstree-icon {
  background-image: url('ic-tree.png');
}

.jstree-default-small .jstree-node {
  background-position: -295px -7px;
  background-repeat: repeat-y;
}

.jstree-default-small .jstree-last {
  background: transparent;
}

.jstree-default-small .jstree-open > .jstree-ocl {
  background-position: -135px -7px;
}

.jstree-default-small .jstree-closed > .jstree-ocl {
  background-position: -103px -7px;
}

.jstree-default-small .jstree-leaf > .jstree-ocl {
  background-position: -71px -7px;
}

.jstree-default-small .jstree-themeicon {
  background-position: -263px -7px;
}

.jstree-default-small > .jstree-no-dots .jstree-node,
.jstree-default-small > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: transparent;
}

.jstree-default-small > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -39px -7px;
}

.jstree-default-small > .jstree-no-dots .jstree-closed > .jstree-ocl {
  background-position: -7px -7px;
}

.jstree-default-small .jstree-disabled {
  background: transparent;
}

.jstree-default-small .jstree-disabled.jstree-hovered {
  background: transparent;
}

.jstree-default-small .jstree-disabled.jstree-clicked {
  background: #efefef;
}

.jstree-default-small .jstree-checkbox {
  background-position: -167px -7px;
}

.jstree-default-small .jstree-checkbox:hover {
  background-position: -167px -39px;
}

.jstree-default-small.jstree-checkbox-selection
  .jstree-clicked
  > .jstree-checkbox,
.jstree-default-small .jstree-checked > .jstree-checkbox {
  background-position: -231px -7px;
}

.jstree-default-small.jstree-checkbox-selection
  .jstree-clicked
  > .jstree-checkbox:hover,
.jstree-default-small .jstree-checked > .jstree-checkbox:hover {
  background-position: -231px -39px;
}

.jstree-default-small .jstree-anchor > .jstree-undetermined {
  background-position: -199px -7px;
}

.jstree-default-small .jstree-anchor > .jstree-undetermined:hover {
  background-position: -199px -39px;
}

.jstree-default-small .jstree-checkbox-disabled {
  opacity: 0.8;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='jstree-grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#jstree-grayscale");
  /* Firefox 10+ */
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+ & Safari 6+ */
}

.jstree-default-small > .jstree-striped {
  background-size: auto 36px;
}

.jstree-default-small.jstree-rtl .jstree-node {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==');
  background-position: 100% 1px;
  background-repeat: repeat-y;
}

.jstree-default-small.jstree-rtl .jstree-last {
  background: transparent;
}

.jstree-default-small.jstree-rtl .jstree-open > .jstree-ocl {
  background-position: -135px -39px;
}

.jstree-default-small.jstree-rtl .jstree-closed > .jstree-ocl {
  background-position: -103px -39px;
}

.jstree-default-small.jstree-rtl .jstree-leaf > .jstree-ocl {
  background-position: -71px -39px;
}

.jstree-default-small.jstree-rtl > .jstree-no-dots .jstree-node,
.jstree-default-small.jstree-rtl > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: transparent;
}

.jstree-default-small.jstree-rtl > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -39px -39px;
}

.jstree-default-small.jstree-rtl
  > .jstree-no-dots
  .jstree-closed
  > .jstree-ocl {
  background-position: -7px -39px;
}

.jstree-default-small .jstree-themeicon-custom {
  background-color: transparent;
  background-image: none;
  background-position: 0 0;
}

.jstree-default-small > .jstree-container-ul .jstree-loading > .jstree-ocl {
  background: url('throbber.gif') center center no-repeat;
}

.jstree-default-small .jstree-file {
  background: url('ic-tree.png') -103px -71px no-repeat;
}

.jstree-default-small .jstree-folder {
  background: url('ic-tree.png') -263px -7px no-repeat;
}

.jstree-default-small > .jstree-container-ul > .jstree-node {
  margin-left: 0;
  margin-right: 0;
}

#jstree-dnd.jstree-default-small {
  line-height: 18px;
  padding: 0 4px;
}

#jstree-dnd.jstree-default-small .jstree-ok,
#jstree-dnd.jstree-default-small .jstree-er {
  background-image: url('ic-tree.png');
  background-repeat: no-repeat;
  background-color: transparent;
}

#jstree-dnd.jstree-default-small i {
  background: transparent;
  width: 18px;
  height: 18px;
  line-height: 18px;
}

#jstree-dnd.jstree-default-small .jstree-ok {
  background-position: -7px -71px;
}

#jstree-dnd.jstree-default-small .jstree-er {
  background-position: -39px -71px;
}

.jstree-default-small .jstree-ellipsis {
  overflow: hidden;
}

.jstree-default-small .jstree-ellipsis .jstree-anchor {
  width: calc(100% - 23px);
  text-overflow: ellipsis;
  overflow: hidden;
}

.jstree-default-small .jstree-ellipsis.jstree-no-icons .jstree-anchor {
  width: calc(100% - 5px);
}

.jstree-default-small.jstree-rtl .jstree-node {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAACAQMAAABv1h6PAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMHBgAAiABBI4gz9AAAAABJRU5ErkJggg==');
}

.jstree-default-small.jstree-rtl .jstree-last {
  background: transparent;
}

.jstree-default-large .jstree-node {
  min-height: 32px;
  line-height: 32px;
  margin-left: 32px;
  min-width: 32px;
}

.jstree-default-large .jstree-anchor {
  line-height: 32px;
  height: 32px;
}

.jstree-default-large .jstree-icon {
  width: 32px;
  height: 32px;
  line-height: 32px;
}

.jstree-default-large .jstree-icon:empty {
  width: 32px;
  height: 32px;
  line-height: 32px;
}

.jstree-default-large.jstree-rtl .jstree-node {
  margin-right: 32px;
}

.jstree-default-large .jstree-wholerow {
  height: 32px;
}

.jstree-default-large .jstree-node,
.jstree-default-large .jstree-icon {
  background-image: url('ic-tree.png');
}

.jstree-default-large .jstree-node {
  background-position: -288px 0px;
  background-repeat: repeat-y;
}

.jstree-default-large .jstree-last {
  background: transparent;
}

.jstree-default-large .jstree-open > .jstree-ocl {
  background-position: -128px 0px;
}

.jstree-default-large .jstree-closed > .jstree-ocl {
  background-position: -96px 0px;
}

.jstree-default-large .jstree-leaf > .jstree-ocl {
  background-position: -64px 0px;
}

.jstree-default-large .jstree-themeicon {
  background-position: -256px 0px;
}

.jstree-default-large > .jstree-no-dots .jstree-node,
.jstree-default-large > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: transparent;
}

.jstree-default-large > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -32px 0px;
}

.jstree-default-large > .jstree-no-dots .jstree-closed > .jstree-ocl {
  background-position: 0px 0px;
}

.jstree-default-large .jstree-disabled {
  background: transparent;
}

.jstree-default-large .jstree-disabled.jstree-hovered {
  background: transparent;
}

.jstree-default-large .jstree-disabled.jstree-clicked {
  background: #efefef;
}

.jstree-default-large .jstree-checkbox {
  background-position: -160px 0px;
}

.jstree-default-large .jstree-checkbox:hover {
  background-position: -160px -32px;
}

.jstree-default-large.jstree-checkbox-selection
  .jstree-clicked
  > .jstree-checkbox,
.jstree-default-large .jstree-checked > .jstree-checkbox {
  background-position: -224px 0px;
}

.jstree-default-large.jstree-checkbox-selection
  .jstree-clicked
  > .jstree-checkbox:hover,
.jstree-default-large .jstree-checked > .jstree-checkbox:hover {
  background-position: -224px -32px;
}

.jstree-default-large .jstree-anchor > .jstree-undetermined {
  background-position: -192px 0px;
}

.jstree-default-large .jstree-anchor > .jstree-undetermined:hover {
  background-position: -192px -32px;
}

.jstree-default-large .jstree-checkbox-disabled {
  opacity: 0.8;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='jstree-grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#jstree-grayscale");
  /* Firefox 10+ */
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+ & Safari 6+ */
}

.jstree-default-large > .jstree-striped {
  background-size: auto 64px;
}

.jstree-default-large.jstree-rtl .jstree-node {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==');
  background-position: 100% 1px;
  background-repeat: repeat-y;
}

.jstree-default-large.jstree-rtl .jstree-last {
  background: transparent;
}

.jstree-default-large.jstree-rtl .jstree-open > .jstree-ocl {
  background-position: -128px -32px;
}

.jstree-default-large.jstree-rtl .jstree-closed > .jstree-ocl {
  background-position: -96px -32px;
}

.jstree-default-large.jstree-rtl .jstree-leaf > .jstree-ocl {
  background-position: -64px -32px;
}

.jstree-default-large.jstree-rtl > .jstree-no-dots .jstree-node,
.jstree-default-large.jstree-rtl > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: transparent;
}

.jstree-default-large.jstree-rtl > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -32px -32px;
}

.jstree-default-large.jstree-rtl
  > .jstree-no-dots
  .jstree-closed
  > .jstree-ocl {
  background-position: 0px -32px;
}

.jstree-default-large .jstree-themeicon-custom {
  background-color: transparent;
  background-image: none;
  background-position: 0 0;
}

.jstree-default-large > .jstree-container-ul .jstree-loading > .jstree-ocl {
  background: url('throbber.gif') center center no-repeat;
}

.jstree-default-large .jstree-file {
  background: url('ic-tree.png') -96px -64px no-repeat;
}

.jstree-default-large .jstree-folder {
  background: url('ic-tree.png') -256px 0px no-repeat;
}

.jstree-default-large > .jstree-container-ul > .jstree-node {
  margin-left: 0;
  margin-right: 0;
}

#jstree-dnd.jstree-default-large {
  line-height: 32px;
  padding: 0 4px;
}

#jstree-dnd.jstree-default-large .jstree-ok,
#jstree-dnd.jstree-default-large .jstree-er {
  background-image: url('ic-tree.png');
  background-repeat: no-repeat;
  background-color: transparent;
}

#jstree-dnd.jstree-default-large i {
  background: transparent;
  width: 32px;
  height: 32px;
  line-height: 32px;
}

#jstree-dnd.jstree-default-large .jstree-ok {
  background-position: 0px -64px;
}

#jstree-dnd.jstree-default-large .jstree-er {
  background-position: -32px -64px;
}

.jstree-default-large .jstree-ellipsis {
  overflow: hidden;
}

.jstree-default-large .jstree-ellipsis .jstree-anchor {
  width: calc(100% - 37px);
  text-overflow: ellipsis;
  overflow: hidden;
}

.jstree-default-large .jstree-ellipsis.jstree-no-icons .jstree-anchor {
  width: calc(100% - 5px);
}

.jstree-default-large.jstree-rtl .jstree-node {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAACAQMAAAAD0EyKAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjgIIGBgABCgCBvVLXcAAAAABJRU5ErkJggg==');
}

.jstree-default-large.jstree-rtl .jstree-last {
  background: transparent;
}

@media (max-width: 768px) {
  #jstree-dnd.jstree-dnd-responsive {
    line-height: 40px;
    font-weight: bold;
    font-size: 1.1em;
    text-shadow: 1px 1px white;
  }

  #jstree-dnd.jstree-dnd-responsive > i {
    background: transparent;
    width: 40px;
    height: 40px;
  }

  #jstree-dnd.jstree-dnd-responsive > .jstree-ok {
    background-image: url('40px.png');
    background-position: 0 -200px;
    background-size: 120px 240px;
  }

  #jstree-dnd.jstree-dnd-responsive > .jstree-er {
    background-image: url('40px.png');
    background-position: -40px -200px;
    background-size: 120px 240px;
  }

  #jstree-marker.jstree-dnd-responsive {
    border-left-width: 10px;
    border-top-width: 10px;
    border-bottom-width: 10px;
    margin-top: -10px;
  }
}

@media (max-width: 768px) {
  .jstree-default-responsive {
    /*
	.jstree-open > .jstree-ocl,
	.jstree-closed > .jstree-ocl { border-radius:20px; background-color:white; }
	*/
  }

  .jstree-default-responsive .jstree-icon {
    background-image: url('40px.png');
  }

  .jstree-default-responsive .jstree-node,
  .jstree-default-responsive .jstree-leaf > .jstree-ocl {
    background: transparent;
  }

  .jstree-default-responsive .jstree-node {
    min-height: 40px;
    line-height: 40px;
    margin-left: 40px;
    min-width: 40px;
    white-space: nowrap;
  }

  .jstree-default-responsive .jstree-anchor {
    line-height: 40px;
    height: 40px;
  }

  .jstree-default-responsive .jstree-icon,
  .jstree-default-responsive .jstree-icon:empty {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  .jstree-default-responsive > .jstree-container-ul > .jstree-node {
    margin-left: 0;
  }

  .jstree-default-responsive.jstree-rtl .jstree-node {
    margin-left: 0;
    margin-right: 40px;
    background: transparent;
  }

  .jstree-default-responsive.jstree-rtl .jstree-container-ul > .jstree-node {
    margin-right: 0;
  }

  .jstree-default-responsive .jstree-ocl,
  .jstree-default-responsive .jstree-themeicon,
  .jstree-default-responsive .jstree-checkbox {
    background-size: 120px 240px;
  }

  .jstree-default-responsive .jstree-leaf > .jstree-ocl,
  .jstree-default-responsive.jstree-rtl .jstree-leaf > .jstree-ocl {
    background: transparent;
  }

  .jstree-default-responsive .jstree-open > .jstree-ocl {
    background-position: 0 0px !important;
  }

  .jstree-default-responsive .jstree-closed > .jstree-ocl {
    background-position: 0 -40px !important;
  }

  .jstree-default-responsive.jstree-rtl .jstree-closed > .jstree-ocl {
    background-position: -40px 0px !important;
  }

  .jstree-default-responsive .jstree-themeicon {
    background-position: -40px -40px;
  }

  .jstree-default-responsive .jstree-checkbox,
  .jstree-default-responsive .jstree-checkbox:hover {
    background-position: -40px -80px;
  }

  .jstree-default-responsive.jstree-checkbox-selection
    .jstree-clicked
    > .jstree-checkbox,
  .jstree-default-responsive.jstree-checkbox-selection
    .jstree-clicked
    > .jstree-checkbox:hover,
  .jstree-default-responsive .jstree-checked > .jstree-checkbox,
  .jstree-default-responsive .jstree-checked > .jstree-checkbox:hover {
    background-position: 0 -80px;
  }

  .jstree-default-responsive .jstree-anchor > .jstree-undetermined,
  .jstree-default-responsive .jstree-anchor > .jstree-undetermined:hover {
    background-position: 0 -120px;
  }

  .jstree-default-responsive .jstree-anchor {
    font-weight: bold;
    font-size: 1.1em;
    text-shadow: 1px 1px white;
  }

  .jstree-default-responsive > .jstree-striped {
    background: transparent;
  }

  .jstree-default-responsive .jstree-wholerow {
    border-top: 1px solid rgba(255, 255, 255, 0.7);
    border-bottom: 1px solid rgba(64, 64, 64, 0.2);
    background: #ebebeb;
    height: 40px;
  }

  /* .jstree-default-responsive .jstree-wholerow-hovered {
    background: #e7f4f9;
  } */
  /* .jstree-default-responsive .jstree-wholerow-clicked {
    background: #beebff;
  } */
  .jstree-default-responsive .jstree-children .jstree-last > .jstree-wholerow {
    box-shadow: inset 0 -6px 3px -5px #666666;
  }

  .jstree-default-responsive .jstree-children .jstree-open > .jstree-wholerow {
    box-shadow: inset 0 6px 3px -5px #666666;
    border-top: 0;
    background: red !important;
  }

  .jstree-default-responsive .jstree-children .jstree-open + .jstree-open {
    box-shadow: none;
  }

  .jstree-default-responsive .jstree-node,
  .jstree-default-responsive .jstree-icon,
  .jstree-default-responsive .jstree-node > .jstree-ocl,
  .jstree-default-responsive .jstree-themeicon,
  .jstree-default-responsive .jstree-checkbox {
    background-image: url('40px.png');
    background-size: 120px 240px;
  }

  .jstree-default-responsive .jstree-node {
    background-position: -80px 0;
    background-repeat: repeat-y;
  }

  .jstree-default-responsive .jstree-last {
    background: transparent;
  }

  .jstree-default-responsive .jstree-leaf > .jstree-ocl {
    background-position: -40px -120px;
  }

  .jstree-default-responsive .jstree-last > .jstree-ocl {
    background-position: -40px -160px;
  }

  .jstree-default-responsive .jstree-themeicon-custom {
    background-color: transparent;
    background-image: none;
    background-position: 0 0;
  }

  .jstree-default-responsive .jstree-file {
    background: url('40px.png') 0 -160px no-repeat;
    background-size: 120px 240px;
  }

  .jstree-default-responsive .jstree-folder {
    background: url('40px.png') -40px -40px no-repeat;
    background-size: 120px 240px;
  }

  .jstree-default-responsive > .jstree-container-ul > .jstree-node {
    margin-left: 0;
    margin-right: 0;
  }
}

/* custom */
.tree.ng-star-inserted {
  overflow: initial !important;
}

.jstree-default .jstree-children .jstree-leaf .jstree-themeicon.item_icon {
  width: 6px;
  height: 6px;
  display: inline-block;
  background-image: none;
  background-color: #1fc95c;
}

.jstree-default .jstree-themeicon.red {
  background-color: #ff5046;
}

.jstree-default .jstree-open .jstree-themeicon {
  background-position: -271px -69px;
}

.jstree-default .jstree-node .jstree-building {
  background-position: -107px -70px;
}

.jstree-default .jstree-node .jstree-floor {
  background-position: -136px -72px;
}

.jstree-default .jstree-node .jstree-area {
  background-position: -167px -70px;
}

.jstree-default .jstree-node .jstree-home {
  background-position: -231px -70px;
}

.jstree-default .jstree-node .jstree-panel {
  background-position: -199px -70px;
}

.jstree-default .jstree-node .jstree-status {
  background-image: none;
}

.jstree-default .jstree-node .jstree-status::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-bottom: 4px;
}

.jstree-default .jstree-node .jstree-status-fault::before {
  background-color: #f55547;
}

.jstree-default .jstree-node .jstree-status-alarm::before {
  background-color: #ffbc0a;
}

.jstree-default .jstree-node .jstree-status-normal::before {
  background-color: #1fc95c;
}

.jstree-default .jstree-node .jstree-status-unconnected::before {
  background-color: #003876;
}

.jstree-default .jstree-node[area-level='5'] .jstree-leaf {
  margin-left: 10px;
}

.jstree-default .jstree-themeicon:empty {
  vertical-align: top;
  width: 0;
  height: 6px;
  border-radius: 7px;
  margin-top: 13px;
}

.jstree-default .jstree-home-li > .jstree-icon {
  background-position: -122px -4px;
}

.jstree-default .jstree-node.jstree-home-li {
  /* background-image: url("ic-tree.png"); */
  background-repeat: repeat-y;
  background-position: -293px -69px;
  position: relative;
}

.jstree-closed.jstree-last:before {
  /* content: '';
  display: inline-block; */
  height: 30px;
  border-left: 1px solid #f5f9ff;
  position: absolute;
  top: 10px;
  left: 21px;
}

.jstree-home-li .jstree-open.jstree-last:before {
  content: '';
  display: inline-block;
  min-height: 100%;
  border-left: 1px solid #fff;
  position: absolute;
  top: 10px;
  left: 26px;
}

.jstree-building-li .jstree-children > li:before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 36px;
  background-image: url('ic-tree.png');
  top: 10px;
  left: 14px;
  background-position: -327px -4px;
  background-repeat: repeat-y;
  /* background-color: white; */
}

.jstree-building-li .jstree-children > .jstree-open:before {
  min-height: 100%;
}

.jstree-building-li .jstree-children > li:last-child:before {
  display: none;
}

/* .jstree-leaf .jstree-anchor {
  margin-left: 25px;
} */
.d_stat_normal > a > i {
  background-image: none !important;
}

.d_stat_normal > a > i::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-bottom: 4px;
  background-color: #1fc95c;
}

.d_stat_disconnected > a > i {
  background-image: none !important;
}

.d_stat_disconnected > a > i::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-bottom: 4px;
  background-color: #003876;
}

.d_stat_abnormal > a > i {
  background-image: none !important;
}

.d_stat_abnormal > a > i::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-bottom: 4px;
  background-color: #f55547;
}

.d_stat_alarm > a > i {
  background-image: none !important;
}

.d_stat_alarm > a > i::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-bottom: 4px;
  background-color: #ffbc0a;
}

.jstree-default .jstree-anchor[aria-level='1'] {
  padding-left: 0;
}

.script-group-wrap .jstree-leaf.jstree-last .jstree-icon.jstree-ocl,
.trend-group-wrap .jstree-leaf.jstree-last .jstree-icon.jstree-ocl {
  background: none;
}

.script-group-wrap .jstree-default > .jstree-container-ul > .jstree-node:after,
.trend-group-wrap .jstree-default > .jstree-container-ul > .jstree-node:after {
  display: none;
}

.script-group-wrap .jstree-default .jstree-themeicon:empty,
.trend-group-wrap .jstree-default .jstree-themeicon:empty {
  width: 16px;
  height: 16px;
  display: inline-block;
  background: url('ic-tree.png') -78px -113px;
  margin-top: 9px;
}

.script-group-wrap .jstree-default .script-folder:empty,
.trend-group-wrap .jstree-default .script-folder:empty {
  background-position: -45px -113px;
}

.pop-point-browser .jstree-closed.jstree-last:before {
  display: none;
}

.pop-point-browser .jstree-default .jstree-closed > .jstree-ocl {
  background-position: -195px -107px;
}

.pop-point-browser .jstree-default .jstree-open > .jstree-ocl {
  background-position: -258px -97px;
}

/* 다크테마 */

.theme-dark .script-group-wrap .jstree-default .jstree-themeicon:empty,
.theme-dark .trend-group-wrap .jstree-default .jstree-themeicon:empty {
  background: url('ic-tree-dark.png') -78px -113px;
}

.theme-dark .jstree-default .jstree-node,
.theme-dark .jstree-default .jstree-icon {
  background-image: url('ic-tree-dark.png');
}

.theme-dark .jstree-anchor {
  color: #bbb;
}

.theme-dark.pop-point-browser .jstree-default .jstree-closed > .jstree-ocl {
  background-position: -59px -101px;
}

.theme-dark #dynamic-jstree .jstree-open > .jstree-ocl {
  background-position: -319px -150px
}

.theme-dark .jstree-default .jstree-node {
  background-image: none;
}

.jstree-default.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox,
.jstree-default .jstree-checked > .jstree-checkbox {
  background-position: -291px -64px;
}

.jstree-default .jstree-checkbox {
  background-position: -291px -1px;
}

.jstree-default .jstree-anchor > .jstree-undetermined,
.jstree-default .jstree-anchor > .jstree-undetermined:hover {
  background-position: -291px -32px;
}

.script-group-wrap .jstree-default .jstree-leaf > .jstree-ocl,
.trend-group-wrap .jstree-default .jstree-leaf > .jstree-ocl {
  background-image: none;
}

.script-group-wrap .jstree-default > .jstree-no-dots .jstree-open > .jstree-ocl,
.trend-group-wrap .jstree-default > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -131px 1px;
}

.script-group-wrap
  .jstree-default
  > .jstree-no-dots
  .jstree-closed
  > .jstree-ocl,
.trend-group-wrap
  .jstree-default
  > .jstree-no-dots
  .jstree-closed
  > .jstree-ocl {
  background-position: -98px 1px !important;
}

.theme-dark .jstree-default .jstree-clicked {
  color: #fff;
}

.theme-dark .script-group-wrap .jstree-default .jstree-themeicon:empty,
.theme-dark .trend-group-wrap .jstree-default .jstree-themeicon:empty {
  width: 16px;
  height: 16px;
  display: inline-block;
  background: url('ic-tree.png') -78px -113px;
  margin-top: 9px;
}

.theme-dark .script-group-wrap .jstree-default .script-folder:empty,
.theme-dark .trend-group-wrap .jstree-default .script-folder:empty {
  background-position: -45px -113px;
}

.theme-dark
  .script-group-wrap
  .jstree-default
  > .jstree-no-dots
  .jstree-open
  > .jstree-ocl,
.theme-dark
  .trend-group-wrap
  .jstree-default
  > .jstree-no-dots
  .jstree-open
  > .jstree-ocl {
  background-position: -130px -101px;
}

.theme-dark
  .script-group-wrap
  .jstree-default
  > .jstree-no-dots
  .jstree-closed
  > .jstree-ocl,
.theme-dark
  .trend-group-wrap
  .jstree-default
  > .jstree-no-dots
  .jstree-closed
  > .jstree-ocl {
  background-position: -99px 1px !important;
}

.dynamic-tree-inner.jstree-default
  > .jstree-no-dots
  .jstree-open
  > .jstree-ocl {
  background-position: -232px -232px;
  /* background-position: -319px -150px !important; */
}

.dynamic-tree-inner.jstree-default
  .jstree-node.jstree-open[itemtype='folder#header']
  > .jstree-icon.jstree-ocl {
  background-position: -319px -150px !important;
}
/* 두번째 */

.dynamic-tree-inner.jstree-default
  > .jstree-no-dots
  > .jstree-open.jstree-last
  > .jstree-ocl {
  background-position: -319px -150px !important;
}
.dynamic-tree-inner.jstree-default
  > .jstree-no-dots
  > .jstree-last
  > .jstree-children
  > .jstree-closed.jstree-last
  > .jstree-ocl {
  background-position: -282px -173px !important;
}
.dynamic-tree-inner.jstree-default
  > .jstree-no-dots
  > .jstree-last
  > .jstree-children
  > .jstree-open.jstree-last
  > .jstree-ocl {
  background-position: -319px -150px !important;
}
/* 두번째 */
.dynamic-tree-inner.jstree-default
  > .jstree-no-dots
  .jstree-closed
  > .jstree-ocl {
  background-position: -187px -231px !important;
}
/* 첫번째 */
.dynamic-tree-inner.jstree-default
  > .jstree-no-dots
  > .jstree-closed
  > .jstree-ocl {
  background-position: -282px -174px !important;
}

.dynamic-tree-inner.jstree-default
  > .jstree-leaf.jstree-last
  > .jstree-no-dots
  .jstree-closed
  > .jstree-ocl {
  background-position: -304px -108px !important;
}
.dynamic-tree-inner.jstree-default
  > .jstree-leaf.jstree-last
  > .jstree-no-dots
  .jstree-open
  > .jstree-ocl {
  background-position: -304px -108px !important;
}

.dynamic-tree-inner.jstree-default .jstree-leaf > .jstree-ocl {
  background-position: -78px -14px;
  width: 20px;
  visibility: visible;
}
.dynamic-tree-inner.jstree-default .jstree-leaf.jstree-last > .jstree-ocl {
  background-position: -13px -114px;
  width: 20px;
  visibility: visible;
}

.dynamic-tree-inner.jstree-default .jstree-themeicon:empty {
  width: 21px;
  height: 21px;
  margin-top: 6px;
  background-position: -272px -69px;
}
/* .dynamic-tree-inner.jstree-default .jstree-anchor::before  {

    content: '';
    display: block;
    position: absolute;
    width: 21px;
    height: 32px;
    left: -44px;
    background-image: url(../jstree/ic-tree.png);
    background-position: -334px -91px;

  } */
/* .dynamic-tree-inner.jstree-default .jstree-closed .jstree-anchor[aria-level="3"]::before{
    content: '';
    display: block;
    position: absolute;
    width: 21px;
    height: 32px;
    left: -64px;
    background-image: url(../jstree/ic-tree.png);
    background-position: -334px -91px;

  } */

/* .dynamic-tree-inner.jstree-default .jstree-closed .jstree-anchor[aria-level="4"]::before{
    content: '';
    display: block;
    position: absolute;
    width: 21px;
    height: 32px;
    left: -64px;
    background-image: url(../jstree/ic-tree.png);
    background-position: -334px -91px;
  } */

/* .dynamic-tree-inner.jstree-default .jstree-leaf::before,
  .dynamic-tree-inner.jstree-default .jstree-leaf::before{
    content: '';
    display: block;
    position: absolute;
    width: 21px;
    height: 32px;
    left: -4px;
    background-image: url(../jstree/ic-tree.png);
    background-position: -334px -91px;
   } */

/*    
  .dynamic-tree-inner.jstree-default .jstree-anchor[aria-level="4"]::before{

    display: none;

  }

  .dynamic-tree-inner.jstree-default >.jstree-children > .jstree-last > .jstree-children  > .jstree-leaf::before{

    background-color: red;

  } */

/* .dynamic-tree-inner.jstree-default .jstree-children >.jstree-open::before{
    content: '';
    display: block;
    position: absolute;
    width: 21px;
    height: 100%;
    left: -4px;
    background-image: url(../jstree/ic-tree-line.png);
    background-position: -3px 0;
  } */
/* .dynamic-tree-inner.jstree-default .jstree-open.jstree-last::before, */

/* .dynamic-tree-inner.jstree-default >.jstree-children >.jstree-open::before  {
    background-color: red;
    display: none;
  }

  .dynamic-tree-inner.jstree-default >.jstree-children >.jstree-open> .jstree-children >.jstree-open::before{
    background-color: red;
    display: none;
  }
  .dynamic-tree-inner.jstree-default >.jstree-children >.jstree-open> .jstree-children >.jstree-open.jstree-last .jstree-anchor::before{
    display: none;
  } */

/* .dynamic-tree-inner.jstree-default >.jstree-children >.jstree-closed.jstree-last> .jstree-anchor::before,
  .dynamic-tree-inner.jstree-default >.jstree-children >.jstree-last> .jstree-children >.jstree-closed.jstree-last>.jstree-anchor::before
  {
    display: none;
  } */
/* .dynamic-tree-inner.jstree-default > .jstree-children > .jstree-open.jstree-last> .jstree-anchor::before{
    display: none;
  } */

.dynamic-tree-inner.jstree-default .jstree-open .ic-folder {
  background-position: -122px -151px;
}
.dynamic-tree-inner.jstree-default .jstree-closed .ic-folder {
  background-position: -45px -110px;
}
#dynamic-jstree .jstree-container-ul > .jstree-leaf > .jstree-anchor > .ic-folder {
  background-position: -45px -110px;
}

.dynamic-tree-inner.jstree-default .jstree-node.jstree-last {
  background-image: none;
}
.dynamic-tree-inner.jstree-default
  .jstree-node.jstree-last.jstree-open
  > .jstree-ocl {
  background-position: -319px -150px !important;
}
.dynamic-tree-inner.jstree-default
  .jstree-node.jstree-last.jstree-closed
  > .jstree-ocl {
  background-position: -282px -174px !important;
}

.dynamic-tree-inner.jstree-default .ic-script {
  background-position: -204px -149px !important;
}
.dynamic-tree-inner.jstree-default .ic-crosswind {
  background-position: -204px -149px !important;
}
.dynamic-tree-inner.jstree-default .ic-drawing {
  background-position: -245px -149px !important;
}
.dynamic-tree-inner.jstree-default .ic-asterisk {
  background-position: -245px -149px !important;
}
.dynamic-tree-inner.jstree-default .ic-trend {
  background-position: -163px -149px !important;
}
.dynamic-tree-inner.jstree-default .ic-rainbow {
  background-position: -163px -149px !important;
}
.dynamic-tree-inner.jstree-default .ic-link {
  background-position: -283px -149px !important;
}
.dynamic-tree-inner.jstree-default .ic-dashboard {
  background-position: -47px -189px !important;
}
.dynamic-tree-inner.jstree-default .ic-honeycomb {
  background-position: -47px -189px !important;
}
.dynamic-tree-inner.jstree-default .ic-alarm {
  background-position: -83px -189px !important;
}
.dynamic-tree-inner.jstree-default .ic-zephyr {
  background-position: -83px -189px !important;
}
.dynamic-tree-inner.jstree-default .jstree-icon.hidden {
  width: 0;
}

.theme-dark .dynamic-tree-inner.jstree-default .jstree-default .jstree-node,
.theme-dark .dynamic-tree-inner#dynamic-jstree .jstree-icon {
  background-image: url('/assets/css/white/jstree/ic-tree-dk.png');
}

.dynamic-tree-inner.jstree-default .jstree-node {
  padding-left: 20px !important;
  width: 100%;
  box-sizing: border-box;
}

.dynamic-tree-inner.jstree-default .jstree-node > .jstree-icon.jstree-ocl {
  width: 35px !important;
}
.dynamic-tree-inner.jstree-default .jstree-leaf > .jstree-icon.jstree-ocl {
  width: 20px !important;
}
.dynamic-tree-inner.jstree-default
  .jstree-leaf[itemtype='folder#header']
  > .jstree-icon.jstree-ocl {
  background-position: -13px -114px;
}

.theme-dark .dynamic-tree-inner.jstree-default .jstree-clicked {
  color: #0a78ff;
}

.dynamic-tree-inner.jstree-default li[enabled='false'] a {
  opacity: 0.3;
}

.dynamic-tree-inner.jstree-default .jstree-anchor {
  width: 87%;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
}

.dynamic-tree-inner.jstree-default .jstree-wholerow-ul {
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  /* height: 100%; */
}
.dynamic-tree-inner.jstree-default {
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  height: calc(100% - 10px);
}

.dynamic-tree-wrap
  .dynamic-tree-inner.jstree-default
  .jstree-container-ul::before {
  content: '즐겨찾기';
  display: block;
  height: 40px;
  line-height: 40px;
  padding: 0 16px 0 40px;
  font-size: 14px;
  font-weight: 700;

  background-image: url(./bookmark.svg);
  background-repeat: no-repeat;
  background-position: 16px center;
  border: none;
}
.theme-dark .dynamic-tree-inner.jstree-default .jstree-container-ul::before {
  color: #fff;
}
.dynamic-tree-wrap
  .dynamic-tree-inner.jstree-default
  .jstree-container-ul
  > .jstree-last::before {
  content: '프로젝트';
  display: block;
  height: 40px;
  line-height: 40px;
  padding: 0 16px 0 40px;
  font-size: 14px;
  font-weight: 700;
  background-image: url(./project.svg);
  background-repeat: no-repeat;
  background-position: 16px center;
  top: -40px;
  left: 0;
  position: absolute;
  border: none;
}
.dynamic-tree-wrap.en
  .dynamic-tree-inner.jstree-default
  .jstree-container-ul::before {
  content: 'Bookmark';
}
.dynamic-tree-wrap.en
  .dynamic-tree-inner.jstree-default
  .jstree-container-ul
  > .jstree-last::before {
  content: 'Project';
}
.dynamic-tree-wrap.zh
  .dynamic-tree-inner.jstree-default
  .jstree-container-ul::before {
  content: '收藏';
}
.dynamic-tree-wrap.zh
  .dynamic-tree-inner.jstree-default
  .jstree-container-ul
  > .jstree-last::before {
  content: '计划';
}

.theme-dark
  .dynamic-tree-inner.jstree-default
  .jstree-container-ul
  > .jstree-last::before {
  color: #fff;
}

.dynamic-tree-inner.jstree-default .jstree-container-ul > .jstree-last {
  margin-top: 40px;
  position: relative;
}

.dynamic-tree-inner.jstree-default
  .jstree-last
  .jstree-children
  > .jstree-node
  .jstree-node {
  background-image: url(../jstree/ic-tree-line.png);
  background-repeat: repeat-y;
  background-position: -7px 0;
  /* background-color: red; */
}
.theme-dark
  .dynamic-tree-inner.jstree-default
  .jstree-last
  .jstree-children
  > .jstree-node
  .jstree-node {
  background-image: url(../jstree/ic-tree-line-dark.png);
  background-repeat: repeat-y;
  background-position: -7px 0;
}

.dynamic-tree-inner.jstree-default
  > .jstree-no-dots
  .jstree-last
  .jstree-open
  > .jstree-ocl {
  background-position: -232px -232px;
}

.jstree
  .jstree-open
  > .jstree-children
  > .jstree-node
  .jstree-children
  > .jstree-node.jstree-last.jstree-closed
  .jstree-wholerow
  + .jstree-ocl[aria-expanded='true'] {
  background-position: -282px -174px;
}
.jstree-rename-input {
  background-color: transparent !important;
  background: transparent !important;
}
.dynamic-tree-wrap
  .jstree
  .jstree-open
  > .jstree-children
  > .jstree-node.jstree-last
  > .jstree-children
  > .jstree-node {
  background-image: none;
}
.dynamic-tree-wrap
  .jstree
  .jstree-open
  > .jstree-children
  > .jstree-node
  .jstree-children
  > .jstree-node
  .jstree-wholerow
  + .jstree-ocl[aria-expanded='true'] {
  background-position: -319px -150px !important;
}

/* 립스 트리 */
#dynamic-jstree .start-page {
  margin-right: 25px !important;
}

#dynamic-jstree .start-page::after {
  width: 20px;
  height: 20px;
  content: '';
  display: block;
  background-image: url('/assets/css/white/jstree/ic-star.svg');
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  left: 22px;
  top: -1px;
}